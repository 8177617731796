<template>
  <div id="sidebar" class="sidebar" :class="{
    'sidebar--narrow': isNarrow,
    'sidebar--inactive': !showSidebar,
  }">

    <Header></Header>

    <Meeseeks></Meeseeks>

    <div class="sidebar__wrap" v-if="showSidebar">

      <div class="sidebar__toggle" @click="isNarrow = !isNarrow">
        <svg v-show="isNarrow" width="100%" height="100%"
          version="1.1" viewBox="0 0 20 20" x="0px" y="0px">
          <path d="M4 16V4H2v12h2zM13 15l-1.5-1.5L14 11H6V9h8l-2.5-2.5L13 5l5 5-5 5z"></path>
        </svg>
        <svg v-show="!isNarrow" width="100%" height="100%"
          version="1.1" viewBox="0 0 20 20" x="0px" y="0px">
          <path d="M16 16V4h2v12h-2zM6 9l2.501-2.5-1.5-1.5-5 5 5 5 1.5-1.5-2.5-2.5h8V9H6z"></path>
        </svg>
      </div>

      <div class="sidebar__entities">
        <nav class="sidebar__nav" v-for="(nav, n) in navs" :key="n">
          <ul>
            <li :class="{'is-current': isCurrent(nav)}">
              <router-link
                v-if="!nav.requiredParam || $route.params[nav.requiredParam]"
                :to="nav.link(nav.to)"
                :class="nav.class"
                :style="{backgroundImage: `url(/img/icons/sidebar/${nav.class}.svg)`}">
                {{nav.name(nav.class)}}
              </router-link>
            </li>

            <transition name="accordion"
              v-on:before-enter="beforeEnter" v-on:enter="enter"
              v-on:before-leave="beforeLeave" v-on:leave="leave">
              <ul v-if="nav.links && isCurrent(nav)">
                <li
                  :key="l"
                  v-for="(link, l) in nav.links()">
                  <router-link :to="link.link(link.to)" exact-path>
                    {{link.name(link.class)}}
                  </router-link>
                </li>
              </ul>
            </transition>

          </ul>
        </nav>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/layout/header/Header.vue';
import Meeseeks from '@/components/layout/meeseeks/Meeseeks.vue';
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'Sidebar',
  components: {
    Header,
    Meeseeks,
  },
  data() {
    return {
      activeMenu: '',
      isNarrow: true
    };
  },
  computed: {
    ...mapGetters(['self', 'entity', 'userIsLogged']),
    showSidebar() {
      return this.userIsLogged
    },
    navs() {
      if (!this.self || !this.self.id) return [];

      if (this.self.is_staff) {
        // Staff pages
        return [
          {
            name: this.getName,
            class: 'entities-verification',
            module: 'entities-verification',
            link: this.getRoute,
            to: { name: 'entities-verification', params: { locale: this.$i18n.locale } },
          },
          {
            name: this.getName,
            class: 'report-challenges',
            module: 'report',
            link: this.getRoute,
            to: { name: 'report-challenges', params: { locale: this.$i18n.locale } },
          },
        ];
      }

      if (this.self.entities.length === 0) {
        // New user pages
        return [
          {
            name: this.getName,
            class: 'new-entity',
            module: 'entities',
            link: this.getRoute,
            to: { name: 'new-entity', params: { locale: this.$i18n.locale } },
          },
        ];
      }

      // User with entity already created
      return [
        {
          name: this.getName,
          class: 'entity',
          module: 'entities',
          link: this.getRoute,
          to: urlService.entityRoute(this.self.entities[0], this.$i18n.locale),
        },
        {
          name: this.getName,
          class: 'edit-entity',
          module: 'entities',
          link: this.getRoute,
          to: { name: 'edit-entity', params: { entityid: this.self.entities[0].id, locale: this.$i18n.locale } },
        },
        {
          name: this.getName,
          class: 'new-report',
          module: 'reports',
          link: this.getRoute,
          to: { name: 'new-report', params: { locale: this.$i18n.locale } },
          links: this.getReportLinks,
        },
      ];
    },
  },
  methods: {
    isCurrent(nav) {
      if (this.isNarrow) return false;
      return (this.$route.name === nav.class)
        || (this.$route.meta
          && this.$route.meta.modules
          && nav.module
          && this.$route.meta.modules.includes(nav.module));
    },
    beforeEnter(el) {
      el.style.height = '0'; // eslint-disable-line
    },
    enter(el) {
      el.style.height = `${el.scrollHeight}px`; // eslint-disable-line
    },
    beforeLeave(el) {
      el.style.height = `${el.scrollHeight}px`; // eslint-disable-line
    },
    leave(el) {
      el.style.height = '0'; // eslint-disable-line
    },
    getName(name) {
      return this.$t(`sidebar.${name}`);
    },
    getRoute(route) {
      return route;
    },
    getReportLinks() {
      return this.self.reports.map((r) => ({
        name: () => r.year,
        class: 'report',
        link: this.getRoute,
        module: 'reports',
        to: { name: 'report', params: { locale: this.$i18n.locale, year: r.year } },
      }));
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  font-size: 14px;
  background: #5fac7a;
  border-right: 1px solid #5fac7a;
  color: 1px solid var(--sidebar-text-color-default);
  box-sizing: border-box;
  overflow: hidden;
  transition: flex-basis 250ms ease, max-width 250ms ease;
  max-width: 37px;

  &--narrow {
    max-width: 37px !important;
    .entitychip__name,
    .sidebar__switch {
      display: none;
    }
    .sidebar__entities {
      margin-top: 34px;
    }
  }

  &--inactive {
    width: 0 !important;
    flex: 0 0 0 !important;
    overflow: hidden !important;
    border-right-width: 0px;
    .header {
      margin-left: 0;
      width: 100%;
    }
  }

  &__wrap {
    display: none;
    padding: 44px 0 0;
    transition: opacity 500ms ease-in-out 320ms;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    /*position: fixed;*/
    top: 0;
    left: 0;
  }

  &__toggle {
    text-align: right;
    padding: 7px;
    box-sizing: border-box;
    line-height: 0;
    display: block;
    width: 34px;
    margin-left: auto;
    position: absolute;
    top: 42px;
    right: 0;
    z-index: 9;
    svg {
      cursor: pointer;
      width: 20px;
      fill: var(--sidebar-link-color-active);
    }
  }

  &__switch {
    &-wrap {
      padding: 6px;
      text-align: center;
      cursor: pointer;
      opacity: 0.6;
      &:hover {
        opacity: 1;
        background-color: rgba(0,0,0,0.1);
      }
    }
  }

  &__entities {
    height: calc(100% - 34px);
    overflow: hidden;
    overflow-y: scroll;
    transition: margin-top 250ms ease;

    &::-webkit-scrollbar {
      width: 0;
    }
    &::-webkit-scrollbar-track {
      opacity: 0;
      display: none;
      -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      opacity: 0;
      display: none;
      -webkit-box-shadow: none;
    }
  }

  &__nav {
    a {
      color: var(--sidebar-link-color-default);
      display: block;
      padding: 3px 5px 0;
      margin-left: 11px;
      cursor: pointer;
      transition: color 200ms ease, background-position 250ms ease;
      background-repeat: no-repeat;
      padding-left: 44px;
      background-size: 14px 14px;
      background-position: 1px 6px;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover,
      &:focus,
      &:active {
        color: var(--sidebar-link-color-active);
      }
    }
    ul ul {
      overflow: hidden;
      transition: height 250ms ease-in-out;
      margin-bottom: 6px;
    }
    .is-current a,
    .router-link-exact-active {
      color: var(--sidebar-link-color-active) !important;
    }

    .entitychip {
      &__name {
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 22px;
      }
    }

    > ul > li > a {
      font-family: var(--font-family-display);
      font-weight: 600;
      margin-left: 0;
      padding: 5px 5px 5px 36px;
      background-position: 12px 7px;
      &:hover,
      &:focus {
        background-color: rgba(0,0,0,0.1);
      }
    }

    &--entity {
      > ul > li > a {
        margin-left: 0;
        padding-left: 8px;
      }
    }
  }
}

@media screen and (min-width: 780px) {
  .sidebar {
    max-width: 200px;
    flex: 0 0 200px;
    &__wrap {
      display: block;
    }
  }
}

@media screen and (min-width: 1200px) {
  .sidebar {
    max-width: 280px;
    flex: 0 0 280px;
  }
}

</style>
