module.exports = [
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/components/staticpages/ContactPage.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/termsofservice',
    name: 'tos',
    component: () => import('@/components/staticpages/TosPage.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/privacity',
    name: 'privacity',
    component: () => import('@/components/staticpages/PrivacityPage.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import('@/components/staticpages/CookiesPage.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/components/staticpages/InfoPage.vue'),
    meta: {
      isPublic: true,
    },
  },
];
