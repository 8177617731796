<template>
  <nav class="tabs">
    <ul class="tabs__list" id="tabs">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        class="tabs__item"
        :class="{
          'is-active': currentTab && currentTab.component === tab.component
        }"
        @click="changeTab(tab)">
        <div class="name">{{tab.name}}</div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Tabs',
  data() {
    return {
      isScrolled: false,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    currentTab: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (this.$route.query?.tab) {
      this.$emit('onclick', { component: this.$route.query.tab });
    }
    // Trigger horizontal scroll animation to show hidden tabs
    setTimeout(this.showScroll, 1200);
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab.component } });
      this.$emit('onclick', tab);
    },
    showScroll() {
      if (window.innerWidth < 1200) {
        const tabsWrap = document.getElementById('tabs');
        const limit = 56;
        const steps = 3;

        if (tabsWrap && !this.isScrolled && tabsWrap.scrollLeft < limit) {
          tabsWrap.scrollLeft += steps;
          window.requestAnimationFrame(this.showScroll);
        } else if (tabsWrap && tabsWrap.scrollLeft > 0) {
          this.isScrolled = true;
          tabsWrap.scrollLeft -= steps;
          window.requestAnimationFrame(this.showScroll);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tabs {
  &__list {
    display: flex;
    box-shadow: 0 1px var(--border-color-default);
    margin-bottom: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 0;
    }
    &::-webkit-scrollbar-track {
      opacity: 0;
      display: none;
      -webkit-box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      opacity: 0;
      display: none;
      -webkit-box-shadow: none;
    }
  }
  &__item {
    display: block;
    padding: 2px;
    cursor: pointer;
    background: transparent;
    margin-bottom: 3px;
    margin-right: 30px;
    color: var(--text-color-lighter);
    .name {
      font-weight: 500;
      margin-bottom: 2px;
      font-size: 15px;
      white-space: pre;
      &::selection {background: inherit; color: inherit;}
      &::-moz-selection {background: inherit; color: inherit;}
    }
    &.is-active {
      color: var(--text-color-default);
      box-shadow: 0 3px var(--primary-color-default);
    }
  }
}

@media screen and (min-width: 1200px) {
  .tabs {
    margin: 8px 0 30px;
  }
}
</style>
