<template>
  <Page :classes="['page--home']">

    <ClaimComponent/>

    <SearchComponent
      @search="onSearch"
      :showPhase="false"
      :showChallenge="true"
      :showType="true"
      :showAdm1="true"
      :showSearchButton="true"
      :showEntitiesButton="true"
    ></SearchComponent>

    <div class="spinner__wrap">
      <Spinner
        v-if="isLoading"
        color="#2a2f45"
        :stroke="3"
        :size="50"></Spinner>
    </div>

    <ResultsCount
      v-if="!isLoading"
      :results="stats.count"></ResultsCount>

    <div class="graysection" v-if="stats.count && !isLoading">
    <BlockSubtitle
      modal="resultevolution">{{$t('common.evolution_chart')}}</BlockSubtitle>

      <ChartSlices
        v-if="!isLoading"
        :results="stats.challenges"
        :total="stats.count"
        :oncreated="!isLoading"
        ></ChartSlices>
    </div>

    <TreeMap
      class="restree"
      v-if="stats.count > 0 && !isLoading"
      :results="stats.goals"
      :title="$t('home.priority_sdg')"></TreeMap>

    <div
      class="graysection"
      v-if="stats.count && stats.networks.nodes.length > 0 && !isLoading"
      >
      <ForceChart
        :results="stats.networks"></ForceChart>
    </div>

    <div class="resmap" v-if="stats.coordinates.length && !isLoading">
      <BlockSubtitle modal="map">{{$t('common.results_map')}}</BlockSubtitle>
      <MapPoints
        :lat="40.4169497"
        :lng="-3.7012579"
        :zoom="5"
        :points="stats.coordinates"></MapPoints>
      <EntityTypesLegend/>
    </div>

    <div class="graysection" v-if="!isLoading">
    <ResultsPhasesComponent
      :total="stats.count"
      :results="stats.challenges"></ResultsPhasesComponent>
    </div>

    <ResultsAgendaComponent
      v-if="!isLoading"
      :results="stats.contacts"></ResultsAgendaComponent>

    <div class="graysection" v-if="!isLoading">
    <ResultsDocumentsComponent
      :results="stats.files"></ResultsDocumentsComponent>
    </div>

  </Page>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';
import ClaimComponent from '@/components/claim/ClaimComponent.vue';
import HttpService from '@/services/http/HttpService';
import SearchComponent from '@/components/search/SearchComponent.vue';
import ResultsCount from '@/components/results/count/ResultsCount.vue';
import TreeMap from '@/components/charts/TreeMap.vue';
import ForceChart from '@/components/charts/ForceChart.vue';
import ChartSlices from '@/components/charts/ChartSlices.vue';
import MapPoints from '@/components/layout/map-points/MapPoints.vue';
import ResultsPhasesComponent from '@/components/results/phases/ResultsPhasesComponent.vue';
import ResultsAgendaComponent from '@/components/results/agenda/ResultsAgendaComponent.vue';
import ResultsDocumentsComponent from '@/components/results/documents/ResultsDocumentsComponent.vue';
import EntityTypesLegend from '@/components/legend/EntityTypesLegend.vue';

const urlService = new UrlService();

export default {
  name: 'HomePage',
  components: {
    ClaimComponent,
    SearchComponent,
    ResultsCount,
    TreeMap,
    ForceChart,
    ChartSlices,
    MapPoints,
    ResultsPhasesComponent,
    ResultsAgendaComponent,
    ResultsDocumentsComponent,
    EntityTypesLegend,
  },
  data() {
    return {
      isLoading: false,
      stats: {
        count: 0,
        goals: [],
        challenges: [],
        coordinates: [],
      },
      source: undefined,
    };
  },
  computed: {
    ...mapGetters(['self']),
  },
  mounted() {
    if (this.isEmptySearch()) {
      this.onSearch({});
    }
  },
  methods: {
    async onSearch(filters) {
      this.isLoading = true;
      if (this.source) {
          await this.source.cancel('Cancel previous request');
      }
      const CancelToken = HttpService.CancelToken;
      this.source = CancelToken.source();
      await this.search(filters);
      this.source = undefined;
      this.isLoading = false;
    },
    async search(filters) {
      if (Object.keys(filters).length > 0) {
        await this.getStats(filters);
      } else {
        await this.getStats({});
      }
    },
    async getStats(params) {
      const { data } = await this.$store.dispatch('list', {
        model: 'stats',
        params,
        updateModel: false,
        cancelToken: this.source.token
      });
      this.stats = data
    },
    emptyEntities() {
      this.isLoading = true;
      this.$store.dispatch('list', {
        model: 'stats',
        params: {},
      }).then(({ data }) => {
        this.stats = data;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    isEmptySearch() {
      return urlService.getQueryString(window.location.href) === null;
    },
  },
};
</script>

<style lang="scss">
.page--home {
  max-width: initial !important;
  padding: 0 !important;
  .graysection {
    background-color: var(--background-color-striped);
    padding-bottom: 100px;
    margin-top: 100px;
    display: flow-root;
  }
  .claim,
  .searchcom {
    padding: 20px 10px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  .rescount {
    padding: 40px 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    max-width: 500px;
    font-size: 18px;
    box-sizing: border-box;
  }
  .restree {
    overflow: hidden;
  }
  .entphases,
  .rescontacts,
  .resdocuments {
    padding: 0 20px;
    max-width: var(--layout-max-width-default);
    margin: 0 auto;
  }
  .resmap {
    position: relative;
    h2 {
      position: absolute;
      z-index: 999;
      left: 54px;
      top: 10px;
      pointer-events: none;
    }
    .legendtype {
      z-index: 999;
      padding-right: 36px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .page--home {
    .claim,
    .searchcom {
      max-width: var(--layout-max-width-narrow);
    }
    .resmap {
      padding: 0 20px;
      max-width: var(--layout-max-width-default);
      margin: 0 auto;
    }
  }
}
</style>
