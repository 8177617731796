<template>
  <div class="forcechart" ref="wrap">
    <BlockSubtitle modal="networks">{{$t('common.networks')}}</BlockSubtitle>
    <svg
      class="forcechart__svg"
      ref="svg"
      :width="`${width}px`"
      :height="`${height}px`"
      :viewBox="`0 0 ${width} ${height}`"></svg>
    <EntityTypesLegend/>
  </div>
</template>

<script>
import { select, selectAll } from 'd3-selection';
import { drag } from 'd3-drag';
import { forceSimulation, forceLink, forceManyBody, forceCenter } from 'd3-force';
import EntityTypesLegend from '@/components/legend/EntityTypesLegend.vue';

const d3 = { select, selectAll, drag, forceSimulation, forceLink, forceManyBody, forceCenter };

export default {
  name: 'ForceChart',
  components: {
    EntityTypesLegend,
  },
  data() {
    return {
      width: 200,
    };
  },
  props: {
    results: {
      type: Object,
      required: true,
      default: () => ({ nodes: [], links: [] }),
    },
    height: {
      type: Number,
      default: 300,
    },
    radius: {
      type: Number,
      default: 10,
    },
    strength: {
      type: Number,
      default: -5,
    },
    distance: {
      type: Number,
      default: 100,
    },
  },
  mounted() {
    this.width = this.$refs.wrap.clientWidth;
    this.calculateData(this.results);
  },
  methods: {
    calculateData(data) {
      const links = data.links.map((d) => Object.create(d));
      const nodes = data.nodes.map((d) => Object.create(d));

      const simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink(links).id((d) => d.id).distance(this.distance))
        .force('charge', d3.forceManyBody().strength(this.strength))
        .force('center', d3.forceCenter(
          this.width / 2,
          this.height / 2,
        ));

      const svg = d3.select(this.$refs.svg);

      // Remove previous elements
      svg.selectAll('g').remove();

      const link = svg.append('g')
        .attr('stroke', '#5a617b')
        .attr('stroke-opacity', 0.6)
        .selectAll('line')
        .data(links)
        .join('line')
        .attr('stroke-width', (d) => Math.sqrt(d.value));

      const node = svg.append('g')
        .selectAll('g')
        .data(nodes)
        .join('g');

      node.append('circle')
        .attr('r', this.radius)
        .attr('fill', (d) => d.color);

      node.append('text')
        .attr('fill', '#2a2f45')
        .attr('text-anchor', 'middle')
        .attr('dy', (this.radius * -1) - 4)
        .attr('font-size', '12px')
        .text((d) => (d.color === '#2a2f45' ? d.id : ''));

      simulation.on('tick', () => {
        link
          .attr('x1', (d) => d.source.x)
          .attr('y1', (d) => d.source.y)
          .attr('x2', (d) => d.target.x)
          .attr('y2', (d) => d.target.y);

        node
          .attr('transform', (d) => `translate(${d.x}, ${d.y})`);
      });
    },
  },
  watch: {
    results(results) {
      this.calculateData(results);
    },
  },
};
</script>

<style lang="scss">
.forcechart {
  max-width: var(--layout-max-width-default);
  margin: 0 auto;
  position: relative;

  &__svg {
    display: block;
    margin: 0 auto;
    background: white;
  }
}
</style>
