<template>
  <div class="searchcom">

    <div class="searchcom__filters form">

      <InputSelect
        v-if="universeIsLoaded && showPhase"
        name="phase"
        v-model="inputs.phase"
        :label="$t('common.phase')"
        :options="phasesList"
        ref="phase"
      >
        <template #link>
          <a @click.stop="cleanFieldParam('phase')">
            &times;
          </a>
        </template>
      </InputSelect>

      <InputSelect
        v-if="universeIsLoaded && showChallenge"
        name="field"
        v-model="inputs.challenge"
        :label="$t('common.challenge')"
        :options="challengesList"
        ref="field"
      >
        <template #link>
          <a @click.stop="cleanFieldParam('field')">
            &times;
          </a>
        </template>
      </InputSelect>

      <InputEntityType
        v-if="universeIsLoaded && showType"
        name="entitytype"
        v-model="inputs.entityType"
        :label="$t('common.entity_type')"
        ref="entitytype"
      >
        <template #link>
          <a @click.stop="cleanFieldParam('entitytype')">
            &times;
          </a>
        </template>
      </InputEntityType>

      <InputEntitySector
        v-if="universeIsLoaded && showSector"
        name="entitySector"
        v-model="inputs.entitySector"
        :label="$t('common.sector')"
        ref="entitySector"
      >
        <template #link>
          <a @click.stop="cleanFieldParam('entitySector')">
            &times;
          </a>
        </template>
      </InputEntitySector>

      <InputSelect
        v-if="universeIsLoaded && showAdm1"
        name="entityAdm1"
        v-model="inputs.entityAdm1"
        :label="$t('common.territory')"
        :options="entityAdm1s"
        ref="entityAdm1"
        >
        <template #link>
          <a @click.stop="cleanFieldParam('entityAdm1')">
            &times;
          </a>
        </template>
      </InputSelect>
    </div>

    <div class="searchbox form" v-show="showNameSearch">
      <InputText
        name="search"
        v-model.trim="inputs.entityName"
        :placeholder="$t('common.search_by_name')"></InputText>
    </div>

    <div class="searchcom__sgdlist" v-if="universeIsLoaded">
      <div
        v-for="(sdg, i) in sdgsList"
        :key="i"
        class="searchcom__sdg"
        :class="{'is-selected': sdgIsSelected(sdg)}">
        <div class="searchcom__sdg__image">
        <img
          :src="`/img/${$i18n.locale}/sdg/${sdg.id}.png`"
          :alt="$t(`sdg.${sdg.id}`)"
          :title="$t(`sdg_name.${sdg.id}`)"
          @click="onSelectSDG(sdg)">
          </div>
      </div>
    </div>

    <div class="searchcom__advanced">
      <Button
        v-show="showSearchButton"
        :classes="['btn--accent']">
        {{$t('common.search')}}
      </Button>
      <Button
        v-show="showEntitiesButton"
        @onclick="$router.push({name: 'entities', params: {locale: $i18n.locale }})">
        {{$t('common.see_entities_list')}}
      </Button>
      <Button
        v-show="showHomeButton"
        @onclick="$router.push({name: 'home', params: {locale: $i18n.locale } })">
        {{$t('common.back_to_home')}}
      </Button>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';
import Generator from '@/services/generator/GeneratorService';
import InputEntityType from '@/components/inputs/entity-type/InputEntityType.vue';
import InputEntitySector from '@/components/inputs/entity-sector/InputEntitySector.vue';

const urlService = new UrlService();

export default {
  name: 'SearchComponent',
  emits: ['search'],
  components: {
    InputEntitySector,
    InputEntityType,
  },
  data() {
    return {
      inputs: {
        phase: '',
        challenge: '',
        entityType: '',
        entitySector: '',
        entityAdm1: '',
        entitySDG: '',
        entityName: '',
      },
      filters: {},
      isSearching: false,
    };
  },
  props: {
    showPhase: {
      type: Boolean,
      default: false,
    },
    showChallenge: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: Boolean,
      default: false,
    },
    showSector: {
      type: Boolean,
      default: false,
    },
    showAdm1: {
      type: Boolean,
      default: false,
    },
    showNameSearch: {
      type: Boolean,
      default: false,
    },
    showSearchButton: {
      type: Boolean,
      default: false,
    },
    showEntitiesButton: {
      type: Boolean,
      default: false,
    },
    showHomeButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['self', 'urlParams', 'goals', 'adm1', 'universeIsLoaded', 'challenges']),
    isSearchParamEmpty() {
      return Object.keys(this.urlParams).every((key) => !urlService.getQueryStringParam(
        window.location.href,
        this.urlParams[key],
      ));
    },
    sdgsList() {
      return this.goals
        .filter((sdg) => sdg.type === 'GOAL')
        .map((sdg) => ({
          id: sdg.code,
          color: sdg.color,
        }));
    },
    phasesList() {
      const phaseskeys = this.challenges.reduce((acc, curr) => {
        if (!Object.prototype.hasOwnProperty.call(acc, curr.phase)) acc[curr.phase] = 1;
        return acc;
      }, {});
      return Object.keys(phaseskeys).map((key) => ({
        n: this.$t(`phases.${key}`),
        v: key,
      })).map((phase) => ({
        ...phase,
        n: `${this.$t('common.phase')} ${phase.v}: ${phase.n}`,
      })).sort(Generator.nameSort);
    },
    challengesList() {
      return this.challenges
        .filter((ch) => (
          this.inputs.phase
            ? ch.phase === this.inputs.phase
            : true
        ))
        .map((ch) => ({
          n: this.$t(`challenges.${ch.name}`),
          v: `${ch.id}`,
        })).sort(Generator.nameSort);
    },
    entityAdm1s() {
      return this.adm1.map((d) => ({
        n: this.$t(`adm1.${d.search_name}`),
        v: `${d.id}`,
      }));
    },
  },
  mounted() {
    this.getParamValuesFromUrl();
  },
  methods: {
    onSelectSDG(sdg) {
      if (!this.filters[this.urlParams.entitySDG]) {
        this.inputs.entitySDG = [sdg.id];
        return;
      }
      if (!Array.isArray(this.filters[this.urlParams.entitySDG])) {
        this.filters[this.urlParams.entitySDG] = [this.filters[this.urlParams.entitySDG]];
      }
      const idx = this.filters[this.urlParams.entitySDG].indexOf(`${sdg.id}`);
      if (idx > -1) {
        // remove sdg from filter
        this.filters[this.urlParams.entitySDG].splice(idx, 1);
      } else {
        // add sdg to filter
        this.filters[this.urlParams.entitySDG].push(`${sdg.id}`);
      }
      this.inputs.entitySDG = this.filters[this.urlParams.entitySDG];
      this.updateParam('entitySDG', this.filters[this.urlParams.entitySDG]);
    },
    sdgIsSelected(sdg) {
      if (!this.filters[this.urlParams.entitySDG]) {
        return false;
      }
      if (!Array.isArray(this.filters[this.urlParams.entitySDG])) {
        this.filters[this.urlParams.entitySDG] = [this.filters[this.urlParams.entitySDG]];
      }
      return this.filters[this.urlParams.entitySDG].indexOf(`${sdg.id}`) > -1;
    },
    updateParam(key, value) {
      if (value) {
        this.filters[this.urlParams[key]] = value;
      } else {
        delete this.filters[this.urlParams[key]];
      }
    },
    updateQuery() {
      const queryParams = urlService.objectToQuerystring(this.filters);
      this.$emit('search', { ...this.filters });
      urlService.pushState(`${urlService.baseUrl}${queryParams}`);
    },
    getParamValuesFromUrl() {
      const changes = {};
      Object.keys(this.urlParams).forEach((key) => {
        const value = urlService.getQueryStringParam(
          window.location.href,
          this.urlParams[key],
        );
        if (value) changes[key] = value;
      });
      if (Object.keys(changes).length) {
        this.inputs = { ...this.inputs, ...changes };
      }
    },
    cleanFieldParam(name) {
      this.$refs[name].maybeDeleteValue();
    },
    cleanSearchParams() {
      this.$store.dispatch('emptyModels', 'entity');
      this.inputs.phase = '';
      this.inputs.challenge = '';
      this.inputs.entityType = '';
      this.inputs.entitySector = '';
      this.inputs.entityAdm1 = '';
      this.inputs.entitySDG = '';
      urlService.pushState(urlService.baseUrl);
    },
  },
  watch: {
    inputs: {
      handler(value) {
        Object.keys(this.urlParams).forEach((key) => {
          this.updateParam(key, value[key]);
        });
        this.updateQuery();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.searchcom {
  margin-bottom: 40px;

  .searchbox {
    display: block;
    margin: 0 auto;
    position: relative;
    padding: 0 4px;
    span {
      display: block;
      top: 13px;
      right: 6px;
      position: absolute;
      background-image: url(/img/icons/basicui/095-help.svg);
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
      z-index: 9;
      width: 20px;
      height: 20px;
    }
  }

  .resultsbox {
    display: block;
    margin: 0 auto;
    max-width: 600px;
    position: relative;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    &__item a {
      background: #edf1f7;
      display: block;
      padding: 2px 6px;
    }
    &__empty {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
    }
  }

  .input-block--text {
    input {
      width: 100%;
      padding-right: 30px !important;
    }
  }

  &__sgdlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 28px auto 0;
  }
  &__sdg {
    flex: 0 1 58px;
    &__image {
      padding: 4px;
      img {
        border-radius: 3px;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        cursor: pointer;
        transition: opacity 250ms ease;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    &.is-selected {
      outline: 2px solid var(--primary-color-default);
      outline-offset: -2px;
    }
  }

  &__filters {
    margin-top: -30px;
    display: flex;
    flex-wrap: wrap;
    .input-block {
      margin-top: 12px;
      flex: 1 1 100%;
      padding: 4px;
      input,
      select {
        width: 100%;
      }
    }
  }

  &__advanced {
    margin-top: 20px;
    text-align: center;
  }
}


.input-block.input-block--name-entitySector,
.input-block.input-block--name-entitytype {
  display: none;
}

@media screen and (min-width: 512px) {
  .searchcom {
    &__filters {
      flex-wrap: nowrap;
      .input-block {
        flex: 0 1 100%;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .searchcom {
    .searchbox span {
      top: 28px;
    }
  }
}
</style>
