<!-- eslint-disable max-len -->
<template>
  <div class="crumb crumb--menu" @click="isActive = !isActive">
    <svg
      class="menuimg"
      viewBox="0 -53 384 384"
      width="43.028px"
      height="43.028px"
      xmlns="http://www.w3.org/2000/svg">
      <path class="menupath" d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
      <path class="menupath" d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
      <path class="menupath" d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/>
    </svg>

    <div class="crumb__menu" :class="{'is-active': isActive}">
      <ul class="crumb__menu-list">
        <li class="crumb__menu-item">
          <router-link :to="{name: 'home', params: {locale: $i18n.locale}}">{{$t('common.start')}}</router-link>
        </li>
        <li class="crumb__menu-item">
          <router-link :to="{name: 'entities', params: {locale: $i18n.locale}}">{{$t('common.entities')}}</router-link>
        </li>
        <li class="crumb__menu-item">
          <router-link :to="{name: 'info', params: {locale: $i18n.locale}}">{{$t('common.information')}}</router-link>
        </li>
           <li class="crumb__menu-item">
          <a href="http://observatorio-ods.coop/datos/">Datos</a>
        </li>
             <li class="crumb__menu-item">
          <a href="http://observatorio-ods.coop/legislacion/index.html">Legislación</a>
        </li>
        <li class="crumb__menu-item">
          <router-link :to="{name: 'help', params: {locale: $i18n.locale}}">{{$t('common.help')}}</router-link>
        </li>
        <li class="crumb__menu-item">
          <a href="https://coceta.coop/contacto.asp">{{$t('common.contact')}}</a>
        </li>
      </ul>
    </div>

    <teleport to="#app">
      <Overlay v-if="isActive" @close="isActive = false"></Overlay>
    </teleport>
  </div>
</template>

<script>
export default {
  name: 'CrumbsMenu',
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style lang="scss">
.crumb__menu-item {
  color: rgb(95, 173, 123);
}
.menuimg {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.8);

  .menupath {
    transition: fill 200ms ease;
    fill: var(--primary-color-strong);
  }

  &:hover {
    .menupath {
      fill: var(--primary-color-default);
    }
  }

  &:active {
    .menupath {
      opacity: 0.7;
    }
  }
}
</style>
