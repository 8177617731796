<template>
  <div class="input-certifications">
    <div class="input-certifications__certification" v-for="(certification, i) in value" :key="i">

      <div class="flex">
        <div class="flex__third">
          <InputText
            :name="`certificationname-${i}`"
            :isDisabled="isDisabled"
            :isRequired="true"
            :label="$t('common.name')"
            v-model="value[i].name">
          </InputText>
        </div>
        <div class="flex__third">
          <InputText
            :name="`certificationurl-${i}`"
            :isDisabled="isDisabled"
            :label="$t('common.url')"
            v-model="value[i].url"
            :runValidations="true"
            :validators="urlValidators">
            <span v-if="value[i].url && !urlValidators.every((v) => v(value[i].url))">
              {{$t('error.invalid_url')}}
            </span>
          </InputText>
        </div>
        <div class="flex__third">
          <InputYear
            :name="`certificationyear-${i}`"
            :label="$t('common.year')"
            v-model="value[i].year"
            :isDisabled="isDisabled"
          ></InputYear>
        </div>
      </div>

    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddCertification" @onclick="addCertification()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>

  </div>
</template>

<script>
import Check from '@/services/check/Check';
import InputMixin from '../InputMixin';

export default {
  name: 'InputCertifications',
  mixins: [
    InputMixin,
  ],
  data() {
    return {
      urlValidators: [Check.isValidUrl],
    };
  },
  computed: {
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    canAddCertification() {
      return true;
    },
  },
  methods: {
    addCertification() {
      this.value = [...this.value, {
        name: '',
        url: '',
      }];
    },
  },
};
</script>
