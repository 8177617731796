<template>
<div>
  <section v-if="!userIsLogged" class="logo__section">
    <h2 class="title">OBSERVATORIO DEL COOPERATIVISMO DE TRABAJO. ODS</h2>
    <div class="observ">
    <div>
      <p>Un proyecto de:</p>
      <img class="section-logo lg-1" src="/img/section-footer-1.jpg" alt="">
    </div>
    <div>
      <p>Financiado por:</p>
      <img class="section-logo lg-2" src="/img/section-footer-2.png" alt="">
    </div>
    </div>
    <img class="section-logo" src="/img/section-footer-3.png" alt="">
  </section>
  <div class="footer">
    <div class="footer__wrap">
      <div class="footer__block footer__block--left">
        <img class="footer__logo" src="/brand/logo.png" alt="OpenData">
      </div>
      <div class="footer__block footer__block--center">
        <ul class="footer__menu">
          <li>
            <router-link :to="{name: 'help', params: { locale: $i18n.locale }}">{{$t('common.help')}}</router-link>
          </li>
          <li>
            <router-link :to="{name: 'info', params: { locale: $i18n.locale }}">{{$t('common.information')}}</router-link>
          </li>
          <li>
            <a href="https://openods.es/">OpenODS</a>
          </li>
          <li>
            <a href="https://coceta.coop/contacto.asp">{{$t('common.contact')}}</a>
          </li>
        </ul>
      </div>
      <div class="footer__block footer__block--right">
        <p>{{$t('common.project_by')}}</p>
        <div class="logo__container">
          <img :src="`/img/${$i18n.locale}/footer/pnud.png`" alt="PNUD">
          <img class="footer__logo-2" src="/img/logo.svg" alt="OpenODS">
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <ul>
        <li>2021 OpenData 2030</li>
        <li><a href="https://coceta.coop/aviso-legal.asp">{{$t('tos.title')}}</a></li>
        <li><a href="https://coceta.coop/politica-cookies.asp">{{$t('cookies.title')}}</a></li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['userIsLogged']),
  },
};
</script>

<style lang="scss">

.logo__section {
  text-align: center;
  margin-top: 4rem;

  & .title {
    color:rgb(44, 62, 80);
    font-family: Montserrat, sans-serif;
    margin-bottom: 25px;
    padding: 0 30px;
  }

  & p {
    margin-top: 15px;
  }

  .section-logo {
    width: 15rem;
  }

  .lg-1 {
    position: relative;
    bottom: 20px;
  }
  .lg-2 {
    margin-bottom: 40px;
  }
  .observ {
    display: flex;
    flex-direction: column;
  }
}

.footer {
  margin-top: 40px;
  padding: 20px 10px;
  border-top: 1px solid var(--border-color-default);
  background-color: rgb(44, 62, 80);

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--layout-max-width-default);
    margin: 0 auto;
  }

  &__block {
    flex: 1 1 100%;
    img {
      display: block;
      max-width: 100%;
    }
    &--left {
      img {
        width: 100%;
        max-width: 300px;
        margin: 0 auto 20px auto;
      }
    }
    &--center {
      margin-bottom: 40px;
    }
    &--right {
      display: flex;
      flex-wrap: wrap;
      p {
        flex: 1 1 100%;
        text-align: center;
        margin-bottom: 4px;
      }
      > div {
        flex: 1 1 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      img {
        margin: 0 auto;
        height: auto;
      }
    }
  }
  &__menu {
    text-align: center;
    li {
      display: block;
    }
  }
  &__bottom {
    margin-top: 40px;
    text-align: center;
    li {
      margin-bottom: 6px;
    }
  }
  &__logo-2{
    width: 50%;
  }

   & li,
    a,
    p {
        font-family: Montserrat, sans-serif;
        color: rgb(255, 255, 255);
    }

    .logo__container{
      display: flex;
      flex-direction: row;
    }
}

@media screen and (min-width: 768px) {
  .logo__section {
   .observ {
   flex-direction: row;
   justify-content: space-evenly;
  }
  }
  .footer {
    margin-top: 60px;
    padding: 60px 40px 30px;
    &__wrap {
      flex-wrap: nowrap;
    }
    &__logo {
    position: relative;
    bottom: 35px;
    }
    &__block {
      margin-bottom: 0;
      flex: 1 1 33.33%;
      &--center {
        flex: 1 1 20%;
      }
      &--right {
        display: block;
        p {
          text-align: left;
          margin-bottom: 10px;
        }
        > div {
          display: inline-block;
          margin-right: 10px;
          vertical-align: top;
        }
        img {
          margin-left: 0;
          height: 50px;
        }
      }
    }
    &__menu {
      text-align: left;
      padding: 0 40px;
    }
    &__bottom {
      ul {
        display: flex;
        justify-content: center;
      }
      li {
        margin: 0 10px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .footer {
    &__block {
      &--center {
        flex: 1 1 33.33%;
      }
      &--right {
        img {
          height: 70px;

        }
      }
    }
  }
}
</style>
