import API from '@/services/api/ApiService';
import UrlService from '@/services/url/UrlService';
import HttpService from '@/services/http/HttpService';
import { models, getters } from './apps.models';

const URL = new UrlService();

export default {
  state: {
    entity: { ...models.entity },
    report: { ...models.report },
    user: { ...models.user },
    cnae: { ...models.cnae },
    reportchallenge: { ...models.reportchallenge },
  },
  mutations: {
    updatePagination(state, { model, data }) {
      state[model].pagination = {
        count: data.count,
        next: data.next,
        previous: data.previous,
        offset: data.offset,
        limit: data.limit,
        data: data.data,
      };
    },

    updateModel(state, { model, data }) {
      state[model].item = { ...data };
    },

    updateModels(state, { model, data }) {
      state[model].items = [...data.results];
    },

    patchModel(state, { model, data }) {
      Object.keys(data).forEach((k) => {
        state[model].item[k] = data[k];
      });
    },

    patchModels(state, { model, data }) { // eslint-disable-line
    /*
      state[model].items
        .filter((item) => data.ids.includes(item.id))
        .forEach((item) => { // eslint-disable-line
          Object.keys(data.update).forEach((k) => {
            item = data.update[k]; // eslint-disable-line
          });
        });
    */
    },

    filterModels(state, { model, data }) {
      state[model].items = state[model].items
        .filter((item) => !data.deleted.includes(item.id));
    },
  },
  actions: {
    updatePagination(commit, { model, data }) {
      this.commit('updatePagination', { model, data });
    },

    updateModel(commit, { model, data }) {
      this.commit('updateModel', { model, data });
    },

    updateModels(commit, { model, data }) {
      this.commit('updateModels', { model, data });
    },

    patchModel(commit, { model, data }) {
      this.commit('patchModel', { model, data });
    },

    emptyModel(commit, model) {
      this.commit('updateModel', { model, data: models[model].item });
    },

    emptyModels(commit, model) {
      this.commit('updateModels', { model, data: { results: [] } });
    },

    /**
     * Request callbacks
     */
    httpSuccess(commit, { data, model, updateModel }) {
      if (!updateModel) return;

      if (data.count !== undefined) {
        // Multiple instances update
        this.dispatch('updateModels', { model, data });
        this.dispatch('updatePagination', { model, data });
        return;
      }

      if (data.deleted) {
        // Multiple instances delete
        this.commit('filterModels', { model, data });
      }

      if (data.update) {
        // Multiple instances update
        this.commit('patchModels', { model, data });
      }

      // Single instance update
      this.dispatch('updateModel', { model, data });
    },

    httpError(commit, { response }) {
      if (response.status === 403) {
        this.$router.push({ name: 'logout' });
      }
    },

    /**
     * Retrieve instance
     */
    retrieve(dispatch, { model, id, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.get(API.url(model, id), API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: response.data, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          })
          .finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * Retrieve instance by slug
     */
     retrieveBySlug(dispatch, { model, slug, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.get(API.url(model, null, slug), API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: response.data, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          })
          .finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * List instances
     */
    list(commit, { model, params, updateModel, cancelToken = null }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);
        const endpoint = params
          ? API.url(model) + URL.objectToQuerystring(params)
          : API.url(model);

        HttpService.get(endpoint, { ...API.getHeaders(), cancelToken })
          .then((response) => {
            this.dispatch('httpSuccess', { data: response.data, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          })
          .finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * Create instance
     */
    create(commit, { model, data, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.post(API.url(model), data, API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: response.data, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          }).finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * Update instance partially
     */
    partial_update(commit, { model, id, data, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.patch(API.url(model, id), data, API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: response.data, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          }).finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * Destroy instance
     */
    destroy(commit, { model, id, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.delete(API.url(model, id), API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: {}, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          }).finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * Bulk update instances
     */
    bulkupdate(commit, { model, ids, data, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.post(`${API.url(model)}bulkupdate/`, { ids, update: data }, API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: { ids, update: data }, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          }).finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },

    /**
     * Bulk destroy instances
     */
    bulkdestroy(commit, { model, ids, updateModel }) {
      return new Promise((resolve, reject) => {
        this.dispatch('loadProgress', 20);

        HttpService.post(`${API.url(model)}bulkdestroy/`, { ids }, API.getHeaders())
          .then((response) => {
            this.dispatch('httpSuccess', { data: { deleted: ids }, model, updateModel });
            resolve(response);
          })
          .catch((response) => {
            this.dispatch('httpError', { response });
            reject(response);
          }).finally(() => {
            this.dispatch('loadProgress', 100);
          });
      });
    },
  },
  getters: {
    ...getters,
  },
};
