import axios from 'axios';
import API from '@/services/api/ApiService';
import debounce from 'debounce';

axios.interceptors.response.use((response) => response, (error) => {
  const { config } = error;

  // Logout user if token refresh didn't work or user is disabled
  if (config && config.url === API.url('refresh')) {
    API.clearToken();
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if (axios.isCancel(error)) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if (error.response && error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Token expiration response doesn't have Content-Type header
  // if (config.headers['Content-Type']) {
    //   if (config.url !== API.url('logout')) {
      //     return new Promise((resolve, reject) => {
        //       reject(error);
        //     });
        //   }
        // }

  // Returns error if request is 401 and it has no Authorization token
  if (!config.headers.Authorization) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
  // Try request again with new token or with no token if it has no refresh token
  return axios.post(API.url('refresh'), {}, { withCredentials: true })
  .then((response) => {
    API.setToken(response.data.access);
    config.headers.Authorization = `Bearer ${response.data.access}`;
    return new Promise((resolve, reject) => {
      axios.request(config).then((r) => {
        resolve(r);
      }).catch((e) => {
        reject(e);
      });
    });
  })
  .catch((response) => {
    delete config.headers.Authorization
    return new Promise((resolve, reject) => {
      axios.request(config).then((r) => {
        resolve(r);
      }).catch((e) => {
        reject(e);
      });
    });
  })
});

export default axios;
