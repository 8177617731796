<template>
  <div class="entphases">

    <BlockSubtitle modal="resultphases">{{$t('entity.compliance_by_phases')}}</BlockSubtitle>

    <div v-if="total > 0">
      <div class="entphases__phase" v-for="(phase, i) in phases" :key="i">
        <div class="entphases__phase-name phase-name">
          <img :src="`/img/icons/phases/${i}.png`" :alt="$t(`phases.${i+1}`)">
          <div>{{$t(`phases.${i+1}`)}}</div>
        </div>
        <div class="entphases__phase-challenges">
          <div
            class="entphases__phase-challenge"
            v-for="(challenge, j) in phase"
            :key="j">
            <div :title="$t(`challenges_short.GOV.${challenge.name}`)">{{$t(`challenges.${challenge.name}`)}}</div>
            <div
              class="entphases__phase-challenge--imgs"
              :title="getEntitiesCount(challenge) === 0 ? $t(`tooltip.no_entities`) : $t(`tooltip.entities_$number`, { number: getEntitiesCount(challenge) })">
              <Bar :value="getAccomplishedChallenge(challenge)" :color="getPhaseColor(i)"></Bar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <Warn type="info">{{$t('results.no_results')}}</Warn>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ResultsPhasesComponent',
  props: {
    results: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['challenges', 'entity', 'phaseColors']),
    phases() {
      const blocks = this.challenges.reduce((acc, curr) => {
        const chall = { ...curr, isChecked: false };
        if (Object.prototype.hasOwnProperty.call(acc, chall.phase)) acc[chall.phase].push(chall);
        else acc[chall.phase] = [chall];
        return acc;
      }, {});
      return Object.keys(blocks).sort().map((key) => blocks[key]);
    },
  },
  methods: {
    getAccomplishedChallenge(challenge) {
      if (!Object.prototype.hasOwnProperty.call(this.results, challenge.name) || !this.total) {
        return 0;
      }
      return Math.round((100 * this.results[challenge.name]) / this.total);
    },
    getPhaseColor(phaseIdx) {
      return this.phaseColors[phaseIdx];
    },
    getEntitiesCount(challenge) {
      if (!Object.prototype.hasOwnProperty.call(this.results, challenge.name) || !this.total) {
        return 0;
      }
      return this.results[challenge.name];
    },
  },
};
</script>
