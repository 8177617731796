<template>
  <div class="input-local-goals">
    <div v-for="(goal, i) in value" :key="i">
      <div class="flex">
        <div class="flex__middle">
          <InputSelect
            v-if="universeIsLoaded"
            :name="`goal-${i}`"
            v-model="value[i].related_goal"
            :label="$t('common.goal')"
            :options="subGoalsList"
          ></InputSelect>
        </div>
        <div class="flex__middle">
          <InputText
            :name="`name-${i}`"
            :isRequired="true"
            :label="$t('common.name')"
            v-model="value[i].name">
          </InputText>
        </div>
      </div>
    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddGoal" @onclick="addGoal()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputMixin from '../InputMixin';

export default {
  name: 'InputLocalGoals',
  mixins: [
    InputMixin,
  ],
  computed: {
    ...mapGetters(['universeIsLoaded', 'goals']),
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    subGoalsList() {
      const getGoalName = (code) => this.$t(`goals.${code.replaceAll('.', '-')}`);
      return this.goals
        .filter((sdg) => sdg.type === 'SUBGOAL')
        .map((g) => ({
          v: +g.id,
          n: `${g.code} ${getGoalName(g.code)}`,
        }));
    },
    canAddGoal() {
      return true;
    },
  },
  methods: {
    addGoal() {
      this.value = [...this.value, {
        related_goal: '',
        name: '',
      }];
    },
  },
};
</script>
