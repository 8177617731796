<template>
  <div class="meeseeks">
    <transition-group name="meeseeks" mode="out-in">
      <div class="meeseeks__wrap" v-for="m in meeseeks" :key="m.start">
        <div class="meeseeks__item" :class="'meeseeks__item--'+m.type">
          {{m.message}}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Meeseeks',
  computed: {
    meeseeks() {
      return this.$store.getters.meeseeks;
    },
  },
};

</script>
<style lang="scss">
.meeseeks {
  position: absolute;
  top: 120px;
  right: 0;
  z-index: 50;
  display: block;
  width: 100%;
  padding: 0 0 5px 0;
  pointer-events: none;
  box-sizing: border-box;
  transition: margin-left 250ms ease, width 250ms ease;

  &__wrap {
    display: flex;
    justify-content: center;
    transition: transform 250ms ease-in-out, opacity 200ms ease;
  }

  &__item {
    width: 100%;
    padding: 8px 10px;
    margin: 0 0 5px;
    background-color: #2a2f45;
    color: white;
    border: 0;
    text-align: center;
    font-size: 14px;
    line-height: 1.25;
    pointer-events: all;

    &--success {
      background-color: var(--status-success-background-color);
      color: var(--status-success-color);
    }

    &--info {
      background-color: var(--status-info-background-color);
      color: var(--status-info-color);
    }

    &--error,
    &--danger {
      background-color: var(--status-alert-background-color);
      color: var(--status-alert-color);
    }

    &--warning {
      background-color: var(--status-warn-background-color);
      color: var(--status-warn-color);
    }
  }

  &-enter-from {
    transform: translate(0, -200px);
  }
  &-leave-to {
    opacity: 0;
  }
}

@media screen and (min-width: 780px) {
  .meeseeks {
    margin-left: 200px;
    width: calc(100% - 200px);
    &__item {
      padding: 14px;
    }
  }
  .sidebar--narrow .meeseeks {
    margin-left: 38px;
    width: calc(100% - 38px);
  }
}

@media screen and (min-width: 1200px) {
  .meeseeks {
    margin-left: 280px;
    width: calc(100% - 280px);
  }
}

</style>
