<template>
  <div class="claim">
    <img class="claim__logo" src="/brand/logo.png" alt="OpenODS">
    <p class="claim__desc">{{$t('home.desc')}}</p>
  </div>
</template>

<script>
export default {
  name: 'ClaimComponent',
};
</script>

<style lang="scss">
.claim {
  text-align: center;
  margin: 40px 0;
  font-size: 1rem;
  color: rgb(160, 160, 160);
  font-family: "Lato", sans-serif;
  &__logo {
    width: 18rem;
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 512px) {
  .claim {
    margin: 80px 0;
  }
}

@media screen and (min-width: 768px) {
  .claim {
    margin: 80px 0 40px;
    &__desc {
      margin: 30px auto 10px;
      max-width: 600px;
    }
  }
}
</style>
