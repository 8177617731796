<template>
  <div class="input-networks">
    <div class="input-networks__network" v-for="(network, i) in value" :key="i">
      <InputSelect
        v-if="universeIsLoaded"
        :name="`network-${i}`"
        v-model="value[i].name"
        :isDisabled="!isEditing(i)"
        :label="isEditing(i) ? $t('common.network') : ''"
        :options="networksList">
        <template #link>
          <a v-if="!isEditing(i)" @click="editThisRow(i)">
            {{$t('common.edit')}}
          </a>
          <a v-if="isEditing(i)" v-show="value[i].global_goal" @click="closeThisRow(i)">
            {{$t('common.save')}}
          </a>
        </template>
      </InputSelect>
    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddNetwork" @onclick="addNetwork()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Check from '@/services/check/Check';
import InputMixin from '../InputMixin';

export default {
  name: 'InputNetworks',
  mixins: [
    InputMixin,
  ],
  data() {
    return {
      urlValidators: [Check.isValidUrl],
      editing: [],
    };
  },
  computed: {
    ...mapGetters(['universeIsLoaded', 'networks']),
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    networksList() {
      return this.networks.map((n) => ({
        v: n.name,
        n: `${n.name} (${n.url})`,
      }));
    },
    canAddNetwork() {
      return true;
    },
  },
  methods: {
    addNetwork() {
      this.value = [...this.value, { name: undefined }];
      this.editing = [this.value.length];
    },
    isEditing(idx) {
      return this.editing.indexOf(idx) > -1;
    },
    editThisRow(idx) {
      this.editing.push(idx);
    },
    closeThisRow(idx) {
      this.editing = this.editing.filter((id) => id !== idx);
    },
  },
};
</script>

<style lang="scss">
.input-networks {
  .input-block {
    padding-top: 0;
    padding-bottom: 0;
    .input-block__alerts {
      display: none;
    }
    .inputselect.is-disabled .inputselect__head .inputselect__selected > div {
      padding: 0;
      padding-right: 50px;
      overflow: hidden;
      span {

      }
    }
  }
  .btn {
    margin-top: 20px;
  }
}
</style>
