<template>
  <div class="header">
    <div class="header__wrap">
      <div class="header__logo">
        <CrumbsMenu />
        <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }">
          <img class="logo1" src="/brand/logo.png" alt="OpenODS" />
        </router-link>
      </div>

      <div class="header__crumbs">
        <a href="http://observatorio-ods.coop/datos/">DATOS</a>
        <a href="http://observatorio-ods.coop/legislacion/index.html">LEGISLACIÓN</a>
        <CrumbsProfile />
      </div>
    </div>
  </div>
</template>

<script>
import CrumbsProfile from '@/components/layout/crumbs/CrumbsProfile.vue';
import CrumbsMenu from '@/components/layout/crumbs/CrumbsMenu.vue';

export default {
  name: 'Header',
  components: {
    CrumbsProfile,
    CrumbsMenu,
  },
};
</script>

<style lang="scss">

.header {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: 9999;
  background: rgb(44, 62, 80);
  border-bottom: 1px solid var(--border-color-dark);
  color: #9fd1b1;

  .crumb {
    line-height: 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    padding: 26px 10px;
    box-sizing: border-box;
    max-width: var(--layout-max-width-default);
    margin: 0 auto;
  }

  &__logo {
    display: flex;
    .crumb--menu {
      margin-right: 20px;
    }
    img {
      width: 100%;
      grid-auto-columns: auto;
      object-fit: scale-down;
    }
  }
  a {
    display: block;
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  &__crumbs {
    display: flex;
    justify-content: center;
    align-items: center;
    > a {
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      font-weight: 700;
      margin-right: 2rem;
      display: none;
      + a {
        margin-left: .5rem;
      }
      &.router-link-exact-active {
        font-weight: 600;
      }
    }
    .crumb {
      line-height: 0;
      margin-left: 18px;
    }
  }
  .logo1 {
    position: relative;
    bottom: 8px;
    height: 70px;
}

    & .langpath,
    .userpath,
    .menupath {
        fill: #9fd1b1;
        stroke: rgb(44, 62, 80);
    }
}

@media screen and (min-width: 780px) {
  .header {
    margin-left: 200px;
    width: calc(100% - 200px);
    transition: margin-left 250ms ease, width 250ms ease;
    &__logo {
      .crumb--menu {
        margin-right: 40px;
      }
    }
  }
  .sidebar--narrow .header {
    margin-left: 38px;
    width: calc(100% - 38px);
  }
}

@media screen and (min-width: 1200px) {
  .header {
    margin-left: 280px;
    width: calc(100% - 280px);
   &__crumbs > a {
      display:inline;
    }
  }
}
</style>
