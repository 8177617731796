<template>
  <div>
    <div v-for="(ind, i) in value" :key="i">
      <InputSelect
        v-if="universeIsLoaded"
        :name="`indicator-${i}`"
        v-model="value[i].indicator"
        :label="$t('common.phase')"
        :options="indicatorsList"
      ></InputSelect>
    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddIndicator" @onclick="addIndicator()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Generator from '@/services/generator/GeneratorService';
import InputMixin from '../InputMixin';

export default {
  name: 'InputIndicators',
  mixins: [
    InputMixin,
  ],
  computed: {
    ...mapGetters(['universeIsLoaded', 'indicators']),
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    indicatorsList() {
      const getIndicatorName = (code) => this.$t(`indicators.${code.replaceAll('.', '-')}`);
      return this.indicators.map((d) => ({
        v: d.id,
        n: `${d.code} ${getIndicatorName(d.code)}`,
      })).sort(Generator.nameSort);
    },
    canAddIndicator() {
      return true;
    },
  },
  methods: {
    addIndicator() {
      this.value = [...this.value, {
        indicator: '',
        type: 'IND',
        source: '?',
      }];
    },
  },
};
</script>
